<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <registration-cover />
      </v-col>

      <v-col col="6" class="form-col">
        <div class="form-col2-container">
          <form novalidate @submit.prevent="signIn" class="top-section">
            <div class="title">Welcome!</div>
            <div class="subtitle" style="margin-top: 0px">
              Log in to SeamlessSource:
            </div>
            <div class="center">
              <div class="md-layout email-field-width">
                <div class="md-layout-item item-size-100">
                  <md-field
                    :class="getValidationClass('email')"
                    class="email-field"
                    id="email"
                  >
                    <div class="email-input">
                      <input
                        class="input-width"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        v-focus
                        autocomplete="email"
                        v-model="model.email"
                        :disabled="signingIn"
                      />
                    </div>
                    <span class="md-error" v-if="emailValidationError">{{
                      emailValidationError
                    }}</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout email-field-width">
                <div class="md-layout-item item-size-100">
                  <md-field
                    :class="getValidationClass('password')"
                    class="email-field"
                    id="password"
                  >
                    <div class="email-input" style="position: relative">
                      <input
                        class="input-width"
                        style="padding-right: 35px"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        id="password"
                        placeholder="Password"
                        v-model="model.password"
                        :disabled="signingIn"
                      />
                      <div
                        class="password-button"
                        @click="togglePasswordVisibility"
                      >
                        <v-icon>{{
                          showPassword ? "mdi-eye-off" : "mdi-eye"
                        }}</v-icon>
                      </div>
                    </div>
                    <span class="md-error" v-if="passwordValidationError">{{
                      passwordValidationError
                    }}</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout email-field-width">
                <div
                  v-if="verified"
                  class="forgot-password"
                  @click="showDialog = true"
                >
                  Forgot Password?
                </div>

                <div v-if="!verified" class="forgot-password">
                  <a
                    class="forgot-password-a"
                    :href="'#'"
                    @click.prevent="sendEmailVerificationLink"
                    >Your account needs to verify, click here</a
                  >
                </div>
              </div>
              <md-button
                type="submit"
                class="wide-button login-button"
                :disabled="saving"
                id="register"
              >
                <div class="signup-text">Log in</div>
              </md-button>

              <md-button
                v-if="ssoEnabled"
                class="margin-s wide-button sign-with-google"
                @click.prevent="authProvider('google')"
                ><img src="../../assets/google_icon.png" width="20px" /> Log in
                WITH GOOGLE
              </md-button>
              <div class="md-layout bottom-section">
                <div class="md-layout-item md-size-100">
                  <div class="new-user-section">
                    <div class="login">
                      New User?
                      <a href="#/register" class="login-link">Sign Up</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </v-col>
    </v-row>
    <md-dialog :md-active.sync="showDialog">
      <div class="form-container">
        <UserForgotPassword @close="closeWindow" />
      </div>
    </md-dialog>
  </v-container>
</template>

<script>
import validationMixin from "../../validation/validation_mixin";
import { required, email } from "vuelidate/lib/validators";
import auth from "../../auth";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import validation from "../../validation";
import restAdapter from "../../restAdapter";
import RegistrationCover from "../../components/RegistrationCover.vue";
import UserForgotPassword from "../user/ForgotPassword.vue";

export default {
  name: "userLogin",
  components: {
    UserForgotPassword,
    RegistrationCover,
  },
  mixins: [validationMixin],
  data: () => ({
    items: [
      {
        src: require(`../../assets/chanodil1.png`),
      },
      {
        src: require(`../../assets/chanodil2.png`),
      },
      {
        src: require(`../../assets/chanodil3.png`),
      },
    ],
    logoImg: require(`../../assets/seamless-source-banner.png`),
    model: {
      email: null,
      password: null,
    },
    signingIn: false,
    verified: true,
    isSubmitting: false,
    showDialog: false,
    showPassword: false,
  }),
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus();
      },
    },
  },
  validations: {
    model: {
      email: {
        required,
        email: (val) => email(validation.emailFormatter(val)),
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.query.status && this.$route.query.status === "verified") {
      notification.success(
        "Your email has been verified successfully. Please login"
      );
    }
  },

  computed: {
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }
      if (!this.$v.model.email.email) {
        return "Invalid email";
      }
      return null;
    },
    passwordValidationError() {
      if (!this.$v.model.password.required) {
        return "The password is required";
      }
      return null;
    },
    ssoEnabled() {
      return conf.sso == true;
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.model.email = null;
      this.model.password = null;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    signIn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.signingIn = true;
      const credentials = {
        email: this.model.email,
        password: this.model.password,
      };
      auth
        .login(credentials)
        .then((response) => {
          this.signingIn = false;
          this.$router.push(this.$route.query.redirect || "/app");
        })
        .catch((error) => {
          this.signingIn = false;
          const attemptsLeft = error.response.data.remaining_invalid_attempts;
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            const errors = {
              emailPassword: ["Invalid email or password"],
            };
            if (attemptsLeft >= 0) {
              errors.loginLimit = [
                `You have ${attemptsLeft} login ${
                  attemptsLeft === 1 ? "attempt" : "attempts"
                } remaining`,
              ];
            }
            notification.errors(errors);
          } else if (error.response.status === 406) {
            auth.user.isRememberToken = true;
            auth.user.rememberToken = error.response.data.token;
            auth.user.token = null;
            auth.user.email = this.model.email;
            auth.setAuth(auth.user);

            notification.errors({
              emailPassword: [
                "Email Not Verified! Please check your inbox or resend the verification email",
              ],
            });
            this.verified = false;
          } else if (error.response.status === 429) {
            notification.errors({
              loginLimit: [
                "Your account has been temporarily disabled. Please try again after 1 hour",
              ],
            });
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },
    authProvider(provider) {
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        })
        .catch((err) => {
          if (err.error) {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    socialLogin(provider, response) {
      auth
        .extractSocialDetails(provider, response)
        .then((response) => {
          if (response.data.already_registered) {
            this.signingIn = false;
            this.$router.push(this.$route.query.redirect || "/app");
            window.location.reload(true);
          } else {
            this.$router.push({ path: "/complete_profile" });
          }
        })
        .catch((err) => {
          notification.error("Something went wrong, please try again later");
        });
    },
    sendEmailVerificationLink() {
      this.isSubmitting = true;
      restAdapter
        .post("/api/email/resend", { email: this.model.email })
        .then((response) => {
          this.isSubmitting = false;
          notification.success(
            "Verification email has been sent to " +
              this.model.email +
              ". " +
              "Please check your inbox"
          );
          auth.user.isRememberToken = false;
          auth.user.rememberToken = null;
          auth.user.token = null;
          auth.setAuth(auth.user);
        })
        .catch((error) => {
          this.isSubmitting = false;

          if (
            error.response &&
            (error.response.status == 400 ||
              error.response.status == 401 ||
              error.response.status == 404)
          ) {
            notification.errors({ emailPassword: ["Invalid email"] });
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },

    closeWindow() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.main-layout {
  width: 100vw;
  height: 100vh;
  padding: 0 0 0 0;
}

.left-side-container {
  margin: 50px 60px;
  width: 300px;
}

.subtitle {
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: $primaryDark;
  text-align: left;
}

.seamlesssource-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $primaryDark;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-col {
  display: flex;
  max-width: 100vw;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

.form-col2-container {
  display: flex;
  max-width: 395px;
  height: 100vh;
}

.md-field.md-theme-default.md-invalid .md-error {
  color: $danger !important;
}

.input {
  background-color: $backgroundColor;
  border-radius: 4px;
  height: 48px;
}

.email-input {
  width: 100%;
  background-color: $backgroundColor;
  border-radius: 4px;
  height: 48px;
}

.password-button {
  cursor: pointer;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 10px;
  background-color: transparent;
  border: none;
}

.password-button > .v-icon {
  color: $primaryDarkest;
}

.input-width {
  width: 100%;
  height: 100%;
  border-bottom: none;
  border: none;
  padding: 12px 20px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-width:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-width::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.terms {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.field-margin {
  margin-bottom: 7px;
  border-bottom: none !important;
  margin-left: auto;
  margin-right: auto;
}

.email-field {
  margin-bottom: 7px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.line {
  height: 3px;
  background: rgba(255, 255, 255, 0.24);
  width: 100% !important;
  margin-top: 10px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.8px;
  margin-top: 15px;
  text-align: left;
  font-weight: 400;
  font-size: 36px;
  line-height: 49px;
  color: $primaryDarkest;
}

.md-field:after {
  height: 0px !important;
}

.v-messages__message {
  color: $primaryLight !important;
  font-weight: bold;
}

#checkboxLabel {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
  color: $primaryDarkest !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 960px) {
  .email-field {
    width: 20.188em;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 4px;
  }

  .line {
    height: 3px;
    background: rgba(255, 255, 255, 0.24);
    max-width: 20.188em;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-col {
    height: 100vh;
    justify-content: center;
    display: flex;
    max-width: 100vw;
    overflow-y: auto;
  }

  .bottom-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-appearance: auto;
  -webkit-text-fill-color: $primaryDarkest;
  transition: background-color 5000s ease-in-out 0s;
}

.wide-button {
  width: 100%;
  min-width: 20.188em;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
}

.sign-with-google {
  background: $paginateText;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
}

.terms-style {
  display: flex;
  flex-direction: row;
}

.signup-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  letter-spacing: 2px;
}

.user-type-label {
  position: relative;
}

.user-type-label::after {
  content: "<>";
}

.user-type-label::before {
  content: "";
}

select option {
  background: rgba(0, 0, 0, 0.3);
  color: $paginateText;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.password-policy {
  padding-bottom: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: $primaryDarkest;
  padding-top: 30px;
}

.forgot-password {
  margin: 13px 0px;
  color: $primaryDark;
  cursor: pointer;
}

.forgot-password-a {
  color: $danger !important;
}

.forgot-password:hover {
  text-decoration: underline;
}

.password-policy__items {
  padding-left: 20px;
  margin-bottom: -10px;
}

.warning-text {
  color: $danger;
}

.item-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.less-padding {
  padding: 0 20px;
}

.transparent-background {
  background: rgba(255, 255, 255, 0.9) !important;
}

.spinner-box {
  position: absolute;
  z-index: 9999;
  top: 50%;
}

.login-link {
  color: $primaryDark !important;
  a {
    color: $primaryDark !important;
  }
}

.login-link:hover {
  text-decoration: underline;
}

.margin-s {
  margin-left: 5px;
}

.login {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $primaryDark;
}

.brand-name-error {
  z-index: 5;
  margin-bottom: 3px;
  color: $paginateText;
  line-height: normal;
}

.new-user-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}

.bottom-section {
  width: 100%;
}

.email-field-width {
  width: 100%;
}

.login-button {
  color: $paginateText;
  background: $primaryDark;
  box-shadow: 0px 12px 14px rgba(91, 61, 135, 0.23);
  border-radius: 7px;
}

.v-messages__message {
  padding-left: 100px;
}

@media only screen and (min-width: 200px) {
  .bottom-section {
    width: 100%;
  }
  .top-section {
    margin-top: auto;
    margin-bottom: auto;
  }
  .password-section {
    margin-bottom: 0.5em;
  }
}

.md-dialog {
  width: 100%;
  height: 100%;
  background: $popupWindow;
  background: rgba(15, 5, 29, 0.78);
  backdrop-filter: blur(4px);
}

.md-dialog::v-deep .md-theme-default {
  border-radius: 8px;
}
</style>
